@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.textcolor {
  color: #222;
}

.bgmain .cardone {
  box-shadow: 0px 0px 10px 1px #e6e4e4 !important;
  padding: 10px 20px;
  background: #feeddb;
  height: 100% !important;
  min-height: 308px;
  border-radius: 10px;
}
.editicone {
  text-align: center;
}
p.bottomtext a {
  color: #05305d;
}
.headspace .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 4px 11px !important;
}
.bgmain .cardtwo {
  background: #fff;
}
h3.textcolor img {
  width: 24px;
  padding-right: 20px;
}

.bgmain .plandetails_button button:nth-child(2) {
  background: #05305d;
  color: #fff;
  margin-left: 38px;
}
.bgmain .plandetails_button button:nth-child(1) {
  background: #ef7f1b;
  color: #fff;

  /* font-family: Poppins-Regular; */
}
.MuiGrid-root.plandetails_button.MuiGrid-item.MuiGrid-grid-md-12 {
  text-align: center;
}
.bgmain .plandetails_button button {
  padding: 10px 27px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-align: center;
  width: 270;
}
.freetr .cardone .MuiTypography-body2 {
  min-height: 105px;
}
.bgmain .cardone button:nth-child(2) {
  background: #05305d;
  color: #fff;
}
.bottomend {
  text-align: center;
  font-size: 14px;
  padding-top: 15px;
}

.bgmain .cardone button:nth-child(1) {
  background: #ef7f1b;
  color: #fff;
  /* font-family: Poppins-Regular; */
}
.bgmain .cardone button {
  padding: 10px 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  width: 175px;
}
.cardtwo li img {
  width: 26px;
}
p.bottomtext {
  text-align: center;
  color: #05305d;
  font-weight: 600;
  padding-top: 50px;
  font-size: 16px;
}
.MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: auto !important;
}
.MuiBackdrop-root {
  opacity: 0 !important;
}
span.MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom {
  color: #05305d !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase !important;
  font-size: 17px;
}
span.MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom {
  float: right;
  font-size: 15px;
  color: #ef7f1b;
  font-weight: 600;
}

p.MuiTypography-root.MuiTypography-body2 ul {
  padding-left: 0px;
}
p.MuiTypography-root.MuiTypography-body2 ul li {
  list-style-type: none !important;
  padding-bottom: 10px;
}
p.error {
  color: rgba(221, 80, 52, 1);
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 13px;
  padding-left: 18px;
  text-align: center;
}
.cardone:nth-child(1) {
  margin-right: 24px;
}
.cardone {
  width: 46%;
}
.bgmain {
  background: #e7eaef;
  border: 1px solid #e7eaef;
  border-radius: 20px;
  padding: 15px 34px !important;
  margin-top: 30px !important;
  /* height: 100%; */
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 80px;
  left: 320px;
}
p.countlogin {
  color: #ef7f1b;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.logintextpo h3 {
  font-size: 20px;
  margin: 6px;
}
h3.textcolor {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  display: flex;
}
.cardbb {
  display: flex;
  justify-content: center;

  align-items: flex-start;
}

.MuiButton-contained {
  box-shadow: 1px 5px 10px 0px rgb(0 0 0 / 20%),
    1px 2px 2px 0px rgb(0 0 0 / 14%), 6px 5px 5px 0px rgb(0 0 0 / 12%) !important;
}
p.bottomplandetails {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 20px;
  /* font-family: Poppins-SemiBold !important; */
}
p#alert-dialog-description img {
  width: 110px;
}
.MuiDialogContent-root {
  text-align: center;
}
.boxhide {
  display: none;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  width: 400px !important;
  min-height: 300px;
  border-radius: 3%;
  font-family: "Poppins", sans-serif;
  top: 12%;
  left: 3%;
}

p#alert-dialog-description p {
  font-weight: bold;
  color: #05305d;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}
p#alert-dialog-description h3 {
  color: #000;
  font-family: "Poppins", sans-serif;
}
p#alert-dialog-description span {
  font-size: 14px;
  color: #ef7f1a;
}
.logintextpo span {
  color: #ef7f1a;
  font-weight: bold;
  font-size: 14px;
}
.carddetails {
  width: 335px;
}
.listst li {
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.listst li span {
  box-sizing: border-box;
  padding-left: 10px;
  width: calc(100% - 25px);
}
.listst {
  padding-top: 14px;
}
/* login added */
.right_bar span {
  cursor: pointer;
}

.right_bar .minus,
.right_bar .plus {
  width: 17px;
  height: 17px;
  background: #ffffff;
  border-radius: 6px;
  padding: 4px 4px 4px 4px;
  border: 1px solid #082f5a;
  display: inline-block;
  margin: 8px;
  vertical-align: middle;
  text-align: center;
}
.right_bar input {
  height: 40px;
  width: 45px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #082f5a;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
section.main_sec {
  display: flex;
  padding-top: 57px;
}
.left_bar {
  width: 200px;
}
.bgmain {
  font-family: "Poppins", sans-serif;
}
.right_bar {
  width: 900px;

  padding: 30px 40px;
}
.cu_ttl {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding-bottom: 40px;
  color: #092e5a;
  font-size: 20px;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
  background-color: #e7eaef;
}

.pro_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.por_ttl h5 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.cu_ttl i {
  margin-right: 20px;
}
.por_ttl p {
  color: #ef7f1b;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 600;
}
p.log_txt {
  font-weight: 600;
  margin-bottom: 80px;
  font-size: 15px;
}
.con_btn button:hover {
  background: #222;
  cursor: pointer;
}
.con_btn button {
  padding: 14px 20px;
  width: 400px;
  background: #082f5a;
  border-color: #082f5a;
  color: #fff;
  border-radius: 7px;
  font-size: 16px;
  box-shadow: 0px 0px 10px 1px #a3a3a3cc;
}
.con_btn {
  text-align: center;
}
p.bot_txt strong {
  color: #082f5a;
}
p.bot_txt {
  text-align: center;
  color: #141414;
  font-weight: 500;
  padding: 80px 0px;
}
.number {
  display: flex;
  align-items: center;
}
.number p {
  font-weight: 600;
  margin-right: 50px;
  font-size: 15px;
}
.desk_txt {
  display: none;
}

h3.textcolor .plandetails-back-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 5px;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.poplan-details.bgmain {
  padding: 20px 45px !important;
}

/* Responsive Design */
@media only screen and (max-width: 767px) {
  .cardone .MuiCardContent-root {
    padding: 10px 16px 0px 16px;
  }
  .carddetails p.error {
    display: none;
  }
  .cardbb {
    display: grid;
  }
  .bgmain .cardone.cardtwo.carddetails {
    height: 71px !important;
    min-height: 100% !important;
    margin-bottom: 55px !important;
  }
  .listst {
    padding-top: 0;
  }
  .listst li {
    margin-top: 8px;
    font-weight: normal;
  }
  .bgmain .cardone {
    border-radius: 10px;
    height: 200px !important;
    width: 300px;
    margin-bottom: 24px;
    padding-bottom: 20px;
  }
  p.bottomplandetails {
    margin-top: 55px;
    text-align: left;
  }
  .bgmain .plandetails_button button {
    width: 100%;
    margin-bottom: 17px;
  }
  .bgmain .plandetails_button button:nth-child(2) {
    margin-left: 0;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3.MuiGrid-grid-lg-3 {
    display: none;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-3.MuiGrid-grid-md-3.MuiGrid-grid-lg-3 {
    display: none;
  }
  .cardone:nth-child(1) {
    margin-right: 0px;
  }

  /* .MuiCardActions-root.MuiCardActions-spacing {
        position: absolute;
        bottom: 0px;
    } */
  .bgmain .cardone button {
    width: 100%;
    margin-bottom: 10px;
    padding: 12px 18px;
  }
  #strater {
    min-height: 130px;
  }
  /* .MuiCardActions-root.MuiCardActions-spacing {
        position: absolute;
        bottom: 30px;
        display: block;
    } */
  .bgmain {
    position: relative;
    background: #fff;
    border: 1px solid #fff;
    top: 0;
    left: 0;
    margin-top: 40px !important;
  }
  .plancon {
    display: flex;
    justify-content: space-between;
  }

  .bgmain .cardone {
    box-shadow: 0px 1px 1px 3px #f4f4f4 !important;
    min-height: 350px;
  }
  p.MuiTypography-root.MuiTypography-body2 ul li {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 4px;
  }
  p.bottomtext {
    display: none;
  }
  .bgmain .cardone button:nth-child(2) {
    background: #05305d;
    color: #fff;
    margin-left: 0;
  }
  .bgmain .cardone.cardtwo {
    height: 71px !important;
    min-height: 195px;
    margin-bottom: 50px;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
    margin: 0px !important;
  }
  .bgmain .MuiCardActions-root {
    padding: 0 !important;
  }

  .lgcart
    .MuiGrid-root.bgmain.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-7 {
    height: 100vh;
  }
  .transtatus .MuiPaper-elevation24 {
    box-shadow: 0px 11px 15px -7px #fff, 0px 24px 38px 3px #fff,
      0px 9px 46px 8px #fff !important;
  }
  .transtatus
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    top: 2%;
  }
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    left: 0%;
    top: 10%;
  }

  .MuiPaper-root.MuiCard-root.cardone.cardtwo.logincar.MuiPaper-elevation1.MuiPaper-rounded {
    height: 150px !important;
    min-height: 220px;
    width: 272px;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
      0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    /* box-shadow: 0 1rem 2rem rgba(0,0,0,.175)!important; */
    margin-bottom: 10px;
    margin-top: 90px;
  }
  .logincar .MuiDialogContent-root:first-child {
    padding-top: 0px;
  }
  .pophide {
    display: none;
  }
  .boxhide {
    display: block;
  }
  .logintextpo h3 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
  }
  .logintextpo span {
    color: #ef7f1a;
    font-weight: bold;
    font-size: 16px;
  }
  .logintextpo {
    text-align: center;
    padding-top: 15px;
  }
  section.main_sec {
    display: block;
  }
  .desk_txt {
    display: block;
    color: #ef7f1b;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 600;
  }
  .checkIcon {
    color: "green";
    margin-left: 20px;
  }
  .cardone .MuiCardActions-root {
    display: block;
  }
  .selectedCard {
    box-shadow: 0px 0px 50px 0px #000;
  }
  .pro_line {
    display: block;
  }
  .por_ttl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .por_ttl h5 {
    margin-top: 0px !important;
  }
  .por_ttl p {
    margin-top: 10px !important;
  }
  .left_bar {
    width: 100% !important;
    padding-bottom: 20px;
  }
  .right_bar {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }

  .con_btn button {
    width: 100%;
  }
  p.log_txt {
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 30px;
    font-size: 14px;
  }
  h3.textcolor .plandetails-back-btn {
    display: none;
  }
  .poplan-details.bgmain {
    padding: 20px !important;
  }
}
/* Ipad Responsive */
@media (max-width: 991px) and (min-width: 768px) {
}
/* siebar css */
/* .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-2.MuiGrid-grid-sm-3.MuiGrid-grid-md-3.MuiGrid-grid-lg-3 {
    margin-top: 60px;
}
.MuiGrid-grid-lg-3 {
    flex-grow: 0;
    max-width: 22%!important;
    flex-basis: 22% !important;
} */
